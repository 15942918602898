import React from "react"
import { Provider } from "react-redux"
import type { GatsbyBrowser } from "gatsby"
import store from "./src/redux/store"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <Provider store={store}>
      <>{element}</>
    </Provider>
  )
}
